import { connect } from 'react-redux';
import Home from '../components/home';
import { setUIState } from '../state/ui-actions';
import { fetchReport } from '../state/assessment-actions';
import { uploadStudentCSV, updateUser } from '../state/user-actions';

const mapStateToProps = state => ({
    user: state.user ? state.user : null,
    loggingIn: typeof (state.ui.loggingIn) !== 'undefined' ? state.ui.loggingIn : false,
    reportDates: typeof (state.ui.reportDates) !== 'undefined' ? state.ui.reportDates : [],
    fetchingReport: typeof (state.ui.fetchingReport) !== 'undefined' ? state.ui.fetchingReport : false,
    csvDownloadReady: typeof (state.ui.csvDownloadReady) !== 'undefined' ? state.ui.csvDownloadReady : false,
    assessments: state.assessments,
    appMessage: typeof (state.ui.appMessage) !== 'undefined' ? state.ui.appMessage : false,
    studentCSVFile: typeof (state.ui.studentCSVFile) !== 'undefined',
});

const mapDispatchToProps = dispatch => ({
    onLogin: () => {
        window.location.href = '/login.html';
    },
    onEmailChanged: (email) => {
        dispatch(setUIState('emailToApprove', email));
    },
    onSetDates: (dates) => {
        dispatch(setUIState('reportDates', dates));
    },
    onFetchReport: () => {
        dispatch(fetchReport());
    },
    onDownloadReport: () => {
        dispatch(setUIState('csvDownloadReady', false));
    },
    onSetStudentFile: (file) => {
        dispatch(setUIState('studentCSVFile', file));
    },
    onUploadStudentCSV: () => {
        dispatch(uploadStudentCSV());
    },
    onOptOut: (_user, optOut) => {
        const { userData } = _user;
        userData.optOut = optOut;
        dispatch(updateUser(userData));
    },
});

const HomeController = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeController;
