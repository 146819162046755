import { takeLatest, put, select } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { SAGA_LOG_ASSESSMENT, SAGA_FETCH_LATEST_ASSESSMENT, SAGA_FETCH_REPORT, SAGA_FETCH_STUDENT_REPORT, SAGA_FETCH_GENERAL_REPORT, setAssessment, setAssessments, setNoCheckins, setAssessmentCounts } from './assessment-actions';
import { setUIState } from './ui-actions';

function* workerLogAssessment(action) {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }
    yield put(setUIState('logError', false));
    yield put(setUIState('logSaving', true));

    // GET ASSESSMENT

    const { assessment } = action;
    assessment.id = uuid();
    assessment.user = user.userName;
    assessment.email = user.email.toLowerCase();
    let status = 'APPROVED';
    const numQuestions = 10;
    for (let i = 1; i <= numQuestions; i++) {
        const q = `q${i}`;

        if (assessment[q] !== 'no') {
            status = 'DENIED';
        }
    }
    if (assessment.q13 === 'no' && assessment.q14 === 'yes' && assessment.q15 === 'no') {
        status = 'APPROVED';
    }
    if (assessment.q13 === 'no' && assessment.q14 === 'no') {
        status = 'DENIED';
    }
    if (assessment.q13 === 'yes') {
        status = 'APPROVED';
    }

    if (assessment.q13 !== 'no' && assessment.q13 !== 'yes') {
        status = 'APPROVED';
        if (assessment.q14 === 'no' || assessment.q15 === 'yes') {
            status = 'DENIED';
        }
    }

    assessment.status = status;
    assessment.installation = 'RRH';
    if (typeof (user.userData) && user.userData.optOut) {
        assessment.optOut = true;
    }

    // STORE LOCAL

    const local = Object.assign({}, assessment);
    local.created = { seconds: moment().unix() };

    // STORE

    try {
        yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=logAssessment&token=${user.token}&assessment=${encodeURI(JSON.stringify(assessment))}`);
    } catch (e) {
        yield put(setUIState('logSaving', false));
        yield put(setUIState('logError', true));
        return;
    }

    // VALIDATE

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchLatestAssessment&token=${user.token}&cb=${uuid()}`);
    const result = yield res.json();
    const { body } = result;
    const latestAssessment = body.data.length ? body.data[0] : null;

    console.log('CURRENT:', assessment.id);
    console.log('LATEST:', latestAssessment.id);
    if (assessment.id !== latestAssessment.id) {
        yield put(setUIState('logSaving', false));
        yield put(setUIState('logError', true));
        return;
    }

    // WE DON'T NEED THIS BECAUSE WE ARE DOING A HARD REDIRECT
    // yield put(setAssessment(local));
    // yield put(setUIState('logError', false));
    // yield put(setUIState('logSaving', false));

    // yield put(push('/pass'));
    window.location = '/pass';
}

export function* logAssessment() {
    yield takeLatest(SAGA_LOG_ASSESSMENT, workerLogAssessment);
}

function* workerFetchLatestAssessment() {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchLatestAssessment&token=${user.token}&cb=${uuid()}`);
    const result = yield res.json();
    const { body } = result;
    const assessment = body.data.length ? body.data[0] : null;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessment(assessment));
}

export function* fetchLatestAssessment() {
    yield takeLatest(SAGA_FETCH_LATEST_ASSESSMENT, workerFetchLatestAssessment);
}

function* workerFetchReport() {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET DATES

    const dates = yield select(state => state.ui.reportDates);
    const start = moment(dates[0]).startOf('day').unix();
    const end = moment(dates[1]).endOf('day').unix();

    // FETCH ALL ASSESSMENTS

    yield put(setAssessments([]));
    yield put(setUIState('fetchingReport', true));
    yield put(setUIState('csvDownloadReady', false));

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchReport&clean=yes&token=${user.token}&start=${start}&end=${end}&installation=RRH`);
    const result = yield res.json();
    const { body } = result;
    const assessments = body.data;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessments(assessments));
    yield put(setUIState('csvDownloadReady', true));
    yield put(setUIState('fetchingReport', false));
}

export function* fetchReport() {
    yield takeLatest(SAGA_FETCH_REPORT, workerFetchReport);
}


function* workerFetchStudentReport() {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET DATES

    const dates = yield select(state => state.ui.reportDates);
    const start = moment(dates[0]).startOf('day').unix();
    const end = moment(dates[1]).endOf('day').unix();

    // FETCH ALL ASSESSMENTS

    yield put(setAssessments([]));
    yield put(setNoCheckins([]));
    yield put(setAssessmentCounts({ approved: 0, denied: 0, noCheckin: 0 }));
    yield put(setUIState('fetchingReport', true));

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchReport&position=STUDENT&token=${user.token}&start=${start}&end=${end}&installation=RRH`);
    const result = yield res.json();
    const { body } = result;
    const assessments = body.data;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessmentCounts({
        approved: body.approved, denied: body.denied, noCheckin: body.noCheckin, byDay: body.byDay, byMonth: body.byMonth,
    }));
    yield put(setAssessments(assessments));
    yield put(setNoCheckins(body.noCheckInUsers));
    yield put(setUIState('fetchingReport', false));
}

export function* fetchStudentReport() {
    yield takeLatest(SAGA_FETCH_STUDENT_REPORT, workerFetchStudentReport);
}

function* workerFetchGeneralReport(action) {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET DATES

    const dates = yield select(state => state.ui.reportDates);
    const start = moment(dates[0]).startOf('day').unix();
    const end = moment(dates[1]).endOf('day').unix();

    // FETCH ALL ASSESSMENTS

    yield put(setAssessments([]));
    yield put(setNoCheckins([]));
    yield put(setAssessmentCounts({ approved: 0, denied: 0, noCheckin: 0 }));
    yield put(setUIState('fetchingReport', true));

    // user.userName = 'rrh_viahealth\\smithd';
    // user.userName = 'rrh_viahealth\\a310540';

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchReport&supervisor=${encodeURI(action.userName)}&token=${user.token}&start=${start}&end=${end}&installation=RRH`);
    const result = yield res.json();
    const { body } = result;
    const assessments = body.data;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessmentCounts({
        approved: body.approved, denied: body.denied, noCheckin: body.noCheckin, byDay: body.byDay, byMonth: body.byMonth,
    }));
    yield put(setAssessments(assessments));
    yield put(setNoCheckins(body.noCheckInUsers));
    yield put(setUIState('fetchingReport', false));
}

export function* fetchGeneralReport() {
    yield takeLatest(SAGA_FETCH_GENERAL_REPORT, workerFetchGeneralReport);
}
