import { takeLatest, put, select } from 'redux-saga/effects';
import { SAGA_INIT_APP, SAGA_SAVE_USER, SAGA_UPLOAD_STUDENT_CSV, SAGA_UPDATE_USER, setUserInfo, saveUser as actionSaveUser } from './user-actions';
import { fetchLatestAssessment } from './assessment-actions';
import { setUIState } from './ui-actions';

function* workerInitApp() {
    // TEMP MESSAGE

    // yield put(setUIState('appMessage', true));
    // return;

    // TOKEN FROM COGNITO

    if (window.location.href.indexOf('login=true') >= 0) {
        yield put(setUIState('loggingIn', true));
    }

    if (window.location.href.indexOf('error') >= 0) {
        const error = window.location.href.split('?')[1].split('=')[1];
        const errorText = unescape(error).split('+').join(' ');
        console.error(errorText);
        alert(errorText);   // eslint-disable-line
        return;
    }

    // Get user token from local storage.

    let token;
    token = window.localStorage.getItem('RRHSAJWToken'); // eslint-disable-line no-undef

    // Get User Info

    let user;
    if (token && typeof (token.length) !== 'undefined') {
        const response = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=getUserInfo&token=${token}`);
        const result = yield response.json();
        // console.log('RESPONSE:', result);
        user = result.body;
    } else {
        console.warn('No JWT Token');
        token = null;
        yield put(setUserInfo(null));
        return;
    }

    // INVALIDATE USER IF ERROR

    if (user.error && user.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        yield put(setUserInfo(null));
        window.location.href = '/login.html';
        return;
    }

    if (user.error) {
        console.error('User token error:', user.error);
        yield put(setUserInfo(null));
        return;
    }

    // STORE USER

    user.token = token;
    yield put(actionSaveUser(Object.assign(user)));
}

export function* initApp() {
    yield takeLatest(SAGA_INIT_APP, workerInitApp);
}

function* workerSaveUser(action) {
    const user = {
        email: action.userInfo.email,
        handle: action.userInfo.firstName,
        firstName: action.userInfo.firstName,
        lastName: action.userInfo.lastName,
        token: action.userInfo.token,
        userName: action.userInfo.userName,
        userData: action.userInfo.userData,
    };

    // SAVE USER

    yield put(setUserInfo(user));

    // FETCH LATEST ASSESSMENT

    if (action.skipFetch !== true) {
        yield put(fetchLatestAssessment());
    }
}

export function* saveUser() {
    yield takeLatest(SAGA_SAVE_USER, workerSaveUser);
}

function* workerUpdateUser(action) {
    const toSave = Object.assign({}, action.user);

    // SEND USING FETCH

    const user = yield select(state => state.user);
    yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=updateUser&token=${user.token}&user=${encodeURI(JSON.stringify(toSave))}`);
    user.userData = toSave;

    console.log('SAVING:', user);
    yield put(actionSaveUser(user, true));
}

export function* updateUser() {
    yield takeLatest(SAGA_UPDATE_USER, workerUpdateUser);
}

// WATCH FOR PATH CHANGES AND DISPATCH APPROPRIATE INIT SAGAS

function* workerWatchLocation(action) { // eslint-disable-line

    const path = action.payload.location.pathname;
    const host = window.location.hostname;  // eslint-disable-line
    const parts = path.split('/');
    const section = parts[1];

    // TEMP MESSAGE

    // yield put(setUIState('appMessage', true));
    // if (section !== '') {
    //     yield put(push('/'));
    // }
    // return;

    // CLEAR DOWNLOAD FLAGS AND ASSESSMENT

    yield put(setUIState('csvDownloadReady', false));
    yield put(setUIState('assessment', null));

    // SWITCH ON SECTION

    switch (section) {
    case (''): {
        break;
    }
    default:
    }
}

export function* watchLocation() {
    yield takeLatest('@@router/LOCATION_CHANGE', workerWatchLocation);
}

const toText = file => new Promise((resolve, reject) => {
    const reader = new FileReader();        // eslint-disable-line
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

function* workerUploadStudentCSV() {
    // GET USER

    const user = yield select(state => state.user);

    // GET CSV FILE OBJECT

    const csvFile = yield select(state => state.ui.studentCSVFile);

    // CONVERT TO BASE 64

    const csvData = yield toText(csvFile);
    const form = {
        method: 'uploadStudentCSV',
        token: user.token,
        csv: csvData,
    };
    const json = JSON.stringify(form);

    // SEND USING FETCH

    const response = yield fetch(
        `https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=uploadStudentCSV&token=${user.token}`,
        {
            method: 'POST',
            body: json,
        },
    );
    yield response.json();
    // console.log('RESPONSE:', result);
}

export function* uploadStudentCSV() {
    yield takeLatest(SAGA_UPLOAD_STUDENT_CSV, workerUploadStudentCSV);
}
