import React from 'react';
import { useStyletron } from 'baseui';

const YesNo = ({
    value, onChange,
}) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <div className={css({ display: 'flex' })}>
            <div
                className={css({
                    backgroundColor: value === 'yes' ? '#acd18c' : '#ffffff',
                    color: value === 'yes' ? '#FFFFFF' : '#000000',
                    borderColor: '#2d9cdb',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderRadius: '50%',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    cursor: 'pointer',
                    marginRight: '15px',
                    ':hover': {
                        backgroundColor: '#cbcbcb',
                        color: '#ffffff',
                    },
                })}
                onClick={() => onChange('yes')}
            >
                <div className={css({
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    position: 'relative',
                    left: '-2px',
                })}
                >YES
                </div>
            </div>

            <div
                className={css({
                    backgroundColor: value === 'no' ? '#acd18c' : '#ffffff',
                    color: value === 'no' ? '#FFFFFF' : '#000000',
                    borderColor: '#2d9cdb',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderRadius: '50%',
                    height: '50px',
                    width: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    cursor: 'pointer',
                    ':hover': {
                        backgroundColor: '#cbcbcb',
                        color: '#ffffff',
                    },
                })}
                onClick={() => onChange('no')}
            >
                <div className={css({
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    position: 'relative',
                    left: '-2px',
                })}
                >NO
                </div>
            </div>
        </div>
    );
};

export default YesNo;
