import { SET_UI_VALUE } from './ui-actions';

export function ui(state = { }, action) {
    switch (action.type) {
    case (SET_UI_VALUE): {
        const newState = Object.assign({}, state);
        newState[action.field] = action.value;
        return newState;
    }
    default:
        return state;
    }
}
