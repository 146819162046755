import { connect } from 'react-redux';
import UpdateApp from '../components/update-app';
import { setUIState } from '../state/ui-actions';

const mapStateToProps = (state) => {
    const isInStandaloneMode = () =>
        (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

    let show = typeof (state.ui.showUpdateApp) !== 'undefined' ? state.ui.showUpdateApp : false;
    show = show && isInStandaloneMode;
    return {
        show,
        site: state.currentSite || {},
        isInStandaloneMode: isInStandaloneMode(),
    };
};

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(setUIState('showUpdateApp', false));
    },
    onReload: () => {
        window.postMessage('Reload app', '*');
    },
});

const UpdateAppController = connect(mapStateToProps, mapDispatchToProps)(UpdateApp);

export default UpdateAppController;
