import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE,
} from 'baseui/modal';

const UpdateApp = ({
    onCancel, onReload, show,
}) => (
    <Modal
        onClose={onCancel}
        closeable
        isOpen={show}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
    >
        <ModalHeader>Reload App?</ModalHeader>
        <ModalBody>
            <p>
                There is a new version of this app. Update and reload?
            </p>
        </ModalBody>
        <ModalFooter>
            <ModalButton onClick={onCancel}>Cancel</ModalButton>
            <ModalButton onClick={onReload}>Okay</ModalButton>
        </ModalFooter>
    </Modal>

);

export default UpdateApp;
