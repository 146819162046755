import { connect } from 'react-redux';
import Questions from '../components/questions';
import { setUIState } from '../state/ui-actions';
import { logAssessment } from '../state/assessment-actions';

const mapStateToProps = state => ({
    assessment: typeof (state.ui.assessment) !== 'undefined' && state.ui.assessment !== null ? state.ui.assessment : {},
    logError: typeof (state.ui.logError) !== 'undefined' ? state.ui.logError : null,
    logSaving: typeof (state.ui.logSaving) !== 'undefined' ? state.ui.logSaving : false,
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    setAssessmentValue: (_assessment, field, value) => {
        const assessment = Object.assign({}, _assessment);
        assessment[field] = value;
        dispatch(setUIState('assessment', assessment));
    },
    onSubmit: (assessment) => {
        dispatch(logAssessment(assessment));
    },
    onLogin: () => {
        window.location.href = '/login.html';
    },
});

const QuestionsController = connect(mapStateToProps, mapDispatchToProps)(Questions);

export default QuestionsController;
