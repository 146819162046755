import { all } from 'redux-saga/effects';
import { initApp, saveUser, watchLocation, uploadStudentCSV, updateUser } from './user-sagas';
import { logAssessment, fetchLatestAssessment, fetchReport, fetchStudentReport, fetchGeneralReport } from './assessment-sagas';

// single entry point to start all Sagas at once

export default function* rootSaga() {
    yield all([
        // USER SAGAS

        initApp(),
        saveUser(),
        updateUser(),
        watchLocation(),
        uploadStudentCSV(),

        // ASSESSMENTS

        logAssessment(),
        fetchLatestAssessment(),
        fetchReport(),
        fetchStudentReport(),
        fetchGeneralReport(),

    ]);
}
