import { SET_ASSESSMENT, SET_NOCHECKINS, SET_ASSESSMENT_COUNTS, SET_ASSESSMENTS } from './assessment-actions';

export function assessment(state = null, action) {
    let newState;
    switch (action.type) {
    case (SET_ASSESSMENT):
        newState = action.assessment;
        return newState;
    default:
        return state;
    }
}

export function assessments(state = [], action) {
    let newState;
    switch (action.type) {
    case (SET_ASSESSMENTS):
        newState = action.assessments;
        return newState;
    default:
        return state;
    }
}

export function noCheckins(state = [], action) {
    let newState;
    switch (action.type) {
    case (SET_NOCHECKINS):
        newState = action.noCheckins;
        return newState;
    default:
        return state;
    }
}

export function assessmentsCounts(state = { approved: 0, denied: 0, noCheckin: 0 }, action) {
    let newState;
    switch (action.type) {
    case (SET_ASSESSMENT_COUNTS):
        newState = action.counts;
        return newState;
    default:
        return state;
    }
}
