import { connect } from 'react-redux';
import Report from '../components/report';
import { setUIState } from '../state/ui-actions';
import { fetchGeneralReport } from '../state/assessment-actions';

const mapStateToProps = state => ({
    user: state.user ? state.user : {},
    showDatePicker: typeof (state.ui.showDatePicker) !== 'undefined' ? state.ui.showDatePicker : false,
    fetchingReport: typeof (state.ui.fetchingReport) !== 'undefined' ? state.ui.fetchingReport : false,
    dateRange: typeof (state.ui.reportDates) !== 'undefined' ? state.ui.reportDates : [],
    assessments: state.assessments,
    noCheckins: state.noCheckins,
    assessmentsCounts: state.assessmentsCounts,
});

const mapDispatchToProps = dispatch => ({
    onShowDatePicker: (value) => {
        dispatch(setUIState('showDatePicker', value));
    },
    onSetDates: (dates) => {
        dispatch(setUIState('reportDates', dates));
        if (dates.length >= 2) {
            dispatch(setUIState('showDatePicker', false));
        }
    },
    onFetchReport: (userName) => {
        dispatch(fetchGeneralReport(userName));
    },
});

const ReportController = connect(mapStateToProps, mapDispatchToProps)(Report);

export default ReportController;
