export const SAGA_LOG_ASSESSMENT = 'SAGA_LOG_ASSESSMENT';
export const SET_ASSESSMENT = 'SET_ASSESSMENT';
export const SET_NOCHECKINS = 'SET_NOCHECKINS';
export const SET_ASSESSMENT_COUNTS = 'SET_ASSESSMENT_COUNTS';
export const SAGA_FETCH_LATEST_ASSESSMENT = 'SAGA_FETCH_LATEST_ASSESSMENT';
export const SET_ASSESSMENTS = 'SET_ASSESSMENTS';
export const SAGA_FETCH_REPORT = 'SAGA_FETCH_REPORT';
export const SAGA_FETCH_STUDENT_REPORT = 'SAGA_FETCH_STUDENT_REPORT';
export const SAGA_FETCH_GENERAL_REPORT = 'SAGA_FETCH_GENERAL_REPORT';

// Store user info into local state.

export function setAssessment(assessment) {
    return {
        type: SET_ASSESSMENT,
        assessment,
    };
}

export function setAssessments(assessments) {
    return {
        type: SET_ASSESSMENTS,
        assessments,
    };
}

export function setNoCheckins(noCheckins) {
    return {
        type: SET_NOCHECKINS,
        noCheckins,
    };
}

export function setAssessmentCounts(counts) {
    return {
        type: SET_ASSESSMENT_COUNTS,
        counts,
    };
}

export function logAssessment(assessment) {
    return {
        type: SAGA_LOG_ASSESSMENT,
        assessment,
    };
}

export function fetchReport() {
    return {
        type: SAGA_FETCH_REPORT,
    };
}

export function fetchLatestAssessment() {
    return {
        type: SAGA_FETCH_LATEST_ASSESSMENT,
    };
}

export function fetchStudentReport() {
    return {
        type: SAGA_FETCH_STUDENT_REPORT,
    };
}

export function fetchGeneralReport(userName) {
    return {
        type: SAGA_FETCH_GENERAL_REPORT,
        userName,
    };
}
