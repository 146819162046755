import React from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { useStyletron } from 'baseui';
import { Helmet } from 'react-helmet';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Header from '../controllers/header';

// Create styles
const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 20,
        paddingTop: 50,
    },
    pass: {
        minHeight: 100,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
});

const PdfPass = ({ assessment, user }) => (
    <Document>
        <Page size="A4" style={pdfStyles.page}>
            <View style={pdfStyles.pass}>
                <View style={{
                    textAlign: 'center', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000000', paddingBottom: 15, paddingTop: 5,
                }}
                >
                    <Text style={{ fontWeight: 'bold' }}>COVID-19 | DAY PASS</Text>
                    <Text style={{ fontSize: 34, color: '#82c341' }}>CLEARED FOR { user && user.userData.position !== 'STUDENT' && <Text>WORK</Text>}{ user && user.userData.position === 'STUDENT' && <Text>CLINICAL</Text>}</Text>
                </View>

                <View style={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000000', padding: 25, paddingTop: 10,
                }}
                >
                    <View style={{ flex: 1 }}>
                        <View style={{ fontWeight: 'bold' }}><Text>{ user && user.userData.position !== 'STUDENT' && <Text>EMPLOYEE</Text>}{ user && user.userData.position === 'STUDENT' && <Text>STUDENT</Text>} NAME</Text></View>
                        <View style={{
                            fontWeight: 'normal',
                            textTransform: 'capitalize',
                        }}
                        ><Text style={{ fontSize: 18, textTransform: 'capitalize' }} render={() => (`${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`)} />
                        </View>
                    </View>

                    <View style={{ flex: 1 }}>
                        <View style={{ fontWeight: 'bold' }}><Text>DATE and TIME</Text></View>
                        <View style={{ fontWeight: 'normal' }}>
                            <Text style={{ fontSize: 18 }} render={() => (moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YYYY'))} />
                            <Text style={{ fontSize: 18, whiteSpace: 'nowrap' }} render={() => (moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('hh:mm A'))} />
                        </View>
                    </View>
                </View>

                <View style={{ paddingBottom: 20 }} >
                    <View style={{
                        fontSize: 16,
                        textAlign: 'center',
                        paddingTop: 10,
                    }}
                    >
                        <Text render={() => (`${user && user.userData.position !== 'STUDENT' ? 'Employee' : 'Student'} Reported NO Symptoms on ${moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YYYY')}`)} />
                    </View>
                    <View style={{
                        marginTop: 20,
                        marginBottom: 20,
                        fontSize: 34,
                        textAlign: 'center',
                        color: '#82c341',
                    }}
                    >
                        <Text>CLEARED FOR { user && user.userData.position !== 'STUDENT' && <Text>WORK</Text>}{ user && user.userData.position === 'STUDENT' && <Text>CLINICAL</Text>}</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>);

const ApprovedPass = ({ assessment, user }) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <div className={css({
            border: 'solid 1px #000000',
            borderRadius: '20px',
            minHeight: '100px',
        })}
        >
            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '22px' },
                })}
                >
                    COVID-19 | DAY PASS
                </div>
                <div className={css({
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#82c341',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '48px' },
                })}
                >
                    CLEARED FOR { user && user.userData.position !== 'STUDENT' && <span>WORK</span>}{ user && user.userData.position === 'STUDENT' && <span>CLINICAL</span>}
                </div>
            </div>

            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingTop: '20px',
                    justifyContent: 'space-between',
                    '@media screen and (min-width: 864px)': {
                        fontSize: '22px',
                        marginLeft: '80px',
                        marginRight: '80px',
                    },
                })}
                >
                    <div>
                        { (user && user.userData.position !== 'STUDENT') && <div className={css({ fontWeight: 'bold' })}>EMPLOYEE NAME</div>}
                        { (user && user.userData.position === 'STUDENT') && <div className={css({ fontWeight: 'bold' })}>STUDENT NAME</div>}
                        <div className={css({
                            fontWeight: 'normal',
                            textTransform: 'capitalize',
                        })}
                        >{user.firstName.toLowerCase()} {user.lastName.toLowerCase()}
                        </div>
                    </div>
                    <div>
                        <div className={css({ fontWeight: 'bold' })}>DATE and TIME</div>
                        <div className={css({ fontWeight: 'normal' })}>{moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YYYY')} <span className={css({ whiteSpace: 'nowrap' })}>{moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('hh:mm A')}</span></div>
                    </div>

                </div>
            </div>

            <div className={css({ paddingBottom: '25px' })} >
                <div className={css({
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '22px' },
                })}
                >
                    { user && user.userData.position !== 'STUDENT' && <span>Employee</span>}{ user && user.userData.position === 'STUDENT' && <span>Student</span>} Reported NO Symptoms on {moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YYYY')}.
                </div>
                <div className={css({
                    marginTop: '20px',
                    marginBottom: '20px',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#82c341',
                    '@media screen and (min-width: 564px)': { fontSize: '48px' },
                })}
                >
                    CLEARED FOR { user && user.userData.position !== 'STUDENT' && <span>WORK</span>}{ user && user.userData.position === 'STUDENT' && <span>CLINICAL</span>}
                </div>
            </div>
        </div>
    );
};

const DeniedPass = ({ assessment, user }) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <div className={css({
            border: 'solid 1px #000000',
            borderRadius: '20px',
            minHeight: '100px',
        })}
        >
            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '22px' },
                })}
                >
                    COVID-19 | DAY PASS
                </div>
                <div className={css({
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#FF0000',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '48px' },
                })}
                >
                    { user && user.userData.position !== 'STUDENT' && <span>STOP: DO NOT REPORT TO WORK</span>}
                    { user && user.userData.position === 'STUDENT' && <span>STOP: DO NOT REPORT TO CLINICAL</span>}
                </div>
            </div>

            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingTop: '20px',
                    justifyContent: 'space-between',
                    '@media screen and (min-width: 864px)': {
                        fontSize: '22px',
                        marginLeft: '80px',
                        marginRight: '80px',
                    },
                })}
                >
                    <div>
                        { (user && user.userData.position === 'STUDENT') && <div className={css({ fontWeight: 'bold' })}>STUDENT NAME</div>}
                        { (user && user.userData.position !== 'STUDENT') && <div className={css({ fontWeight: 'bold' })}>EMPLOYEE NAME</div>}
                        <div className={css({
                            fontWeight: 'normal',
                            textTransform: 'capitalize',
                        })}
                        >{user.firstName.toLowerCase()} {user.lastName.toLowerCase()}
                        </div>
                    </div>
                    <div>
                        <div className={css({ fontWeight: 'bold' })}>DATE and TIME</div>
                        <div className={css({ fontWeight: 'normal' })}>{moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YYYY')} <span className={css({ whiteSpace: 'nowrap' })}>{moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('hh:mm A')}</span></div>
                    </div>

                </div>
            </div>

            <div className={css({ paddingBottom: '25px' })} >
                <div className={css({
                    marginTop: '20px',
                    marginBottom: '20px',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#000000',
                    '@media screen and (min-width: 564px)': { fontSize: '28px' },
                })}
                >
                    { user && user.userData.position !== 'STUDENT' && (
                        <p style={{textAlign: 'left', padding: '25px'}}>
                            1. Please notify your leader.
                            <br />
                            2. Go to <a href="https://www.rochesterregional.org/-/media/files/covid/covid-testing-kb-for-ee1.pdf?la=en&hash=C44B5F00E8660874D48F1EEAE461BF9AA1C35B93" target="_blank" rel="noopener noreferrer">WorkReady</a> directions page to complete your Failed Daily Pass/Symptomatic form.
                            <br />
                        </p>)}
                    { user && user.userData.position === 'STUDENT' && <p>Please work with your school for clearance to return to clinical.</p>}
                </div>
            </div>
        </div>
    );
};

const Pass = ({
    user,
    assessment,
    expired,
}) => {
    // STYLES

    const [css] = useStyletron();

    if (user === null) {
        return <div />;
    }

    return (
        <PrintProvider>
            <NoPrint>
                <Helmet>
                    <title>RRH Self Assessment</title>
                </Helmet>

                <Header />

                <div className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '5%',
                    marginRight: '5%',
                    marginTop: '5%',
                    '@media screen and (min-width: 564px)': { marginLeft: '20%', marginRight: '20%' },
                })}
                >
                    { ((assessment === null || expired === true)) && (
                        <div className={css({
                            border: 'solid 1px #000000',
                            borderRadius: '20px',
                            minHeight: '100px',
                        })}
                        >
                            <div className={css({ marginBottom: '20px', textAlign: 'center' })} >
                                <p>Please complete your self assessment.</p>
                                <Link
                                    className={css({
                                        textDecoration: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '12px', padding: '8px', fontSize: '18px',
                                    })}
                                    to="/"
                                >Let&apos;s Start
                                </Link>
                            </div>
                        </div>)}

                    { (assessment && assessment.status === 'APPROVED' && expired === false) && (
                        <div>
                            <Print>
                                <ApprovedPass assessment={assessment} user={user} />
                            </Print>
                        </div>
                    )}

                    { (assessment && assessment.status === 'APPROVED' && expired === false) && (
                        <div className={css({
                            fontWeight: 'bold',
                            textAlign: 'center',
                        })}
                        >
                            { user && user.userData.position !== 'STUDENT' && <p>Show COVID-19 Day Pass at check in entrance from your device or your printed pass.</p>}
                            { user && user.userData.position === 'STUDENT' && <p>Show COVID-19 Day Pass at check in entrance from your device or your printed pass AND to your RRH clinical faculty or preceptor.</p>}
                            <p>Today&apos;s pass will be saved in My COVID-19 Day Pass section.</p>
                        </div>)}

                    { (assessment && assessment.status === 'APPROVED' && expired === false) && (
                        <div>
                            <div className={css({ textAlign: 'center', marginBottom: '20px' })}>
                                { (assessment && user) && (
                                    <PDFDownloadLink
                                        style={{
                                            whiteSpace: 'nowrap', textDecoration: 'none', marginLeft: '15px', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '12px', padding: '6px', fontSize: '26px',
                                        }}
                                        document={<PdfPass assessment={assessment} user={user} />}
                                        fileName={`DailyPass-${moment().format('MM-DD-YYYY')}.pdf`}
                                    >
                                        {({
                                            loading,
                                        }) => (loading ? 'Loading document...' : 'Download Daily Pass Now')}
                                    </PDFDownloadLink>)}
                            </div>

                            <div className={css({ textAlign: 'center', marginTop: '10px' })}>
                                <button
                                    className={css({
                                        border: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '12px', padding: '8px', fontSize: '18px',
                                    })}
                                    onClick={() => { window.print(); }}
                                >PRINT
                                </button>
                            </div>
                        </div>
                    )}

                    { (assessment && assessment.status === 'DENIED' && expired === false) && (
                        <DeniedPass assessment={assessment} user={user} />
                    )}

                    <div className={css({ height: '75px' })} />

                </div>
            </NoPrint>
        </PrintProvider>
    );
};

export default Pass;
