import React from 'react';
import { StatefulCalendar } from 'baseui/datepicker';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useStyletron } from 'baseui';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../controllers/header';

const Home = ({
    user, onLogin, loggingIn, onSetDates, reportDates, onFetchReport, fetchingReport, csvDownloadReady, onDownloadReport, assessments, appMessage, onSetStudentFile, studentCSVFile, onUploadStudentCSV, onOptOut,
}) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <>
            <Helmet>
                <title>RRH Self Assessment</title>
            </Helmet>

            <Header />

            <div className={css({ display: 'flex', justifyContent: 'center', flexDirection: 'column' })}>

                {(appMessage === true) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <h1>Rochester Regional Health - RRH Daily Pass</h1>
                        <h2>SYSTEM CURRENTLY UNAVAILABLE - DO NOT CALL HELP DESK.</h2>
                        <h2>WE WILL BE BACK UP AGAIN SOON.</h2>
                    </div>
                )}

                {(user === null && loggingIn === false && appMessage === false) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>You are not currently logged in. You must log in as a valid and authorized user to proceed.</p>
                        <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
                    </div>
                )}

                {(user === null && loggingIn === true && appMessage === false) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>Loading... please wait.</p>
                    </div>
                )}

                {(user && appMessage === false) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        { user && user.userData.position !== 'STUDENT' && <h2 className={css({ textAlign: 'left' })}>COMPLETE YOUR SELF ASSESSMENT</h2> }
                        { user && user.userData.position === 'STUDENT' && <h2 className={css({ textAlign: 'left' })}>TAKE YOUR STUDENT SELF ASSESSMENT</h2> }
                        <div className={css({ marginBottom: '20px', textAlign: 'left' })} >
                            { user && user.userData.position !== 'STUDENT' && <p className={css({ textAlign: 'left' })}>Given the COVID-19 pandemic, all Rochester Regional Health employees are required to obtain a daily clearance to work pass by completing this self-assessment no earlier than 2 hours prior to the start of your shift. Upon arrival to work, be prepared to show your daily pass prior to entering your facility.</p> }
                            { user && user.userData.position === 'STUDENT' && <p className={css({ textAlign: 'left' })}>Given the COVID-19 pandemic, all Rochester Regional Health students are required to obtain a daily clearance to work pass by completing this self-assessment no earlier than 2 hours prior to the start of your shift. Upon arrival to work, be prepared to show your daily pass prior to entering your facility.</p> }

                            <br />
                            <br />
                            <Link
                                className={css({
                                    textDecoration: 'none', cursor: 'pointer', backgroundColor: '#334D6E', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                })}
                                to="/assessment"
                            >
                                Let&apos;s Start
                            </Link>
                        </div>
                    </div>
                )}

                {(user && appMessage === false && (user.userData.role === 'ADMIN')) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        <h2>Assessment Log</h2>
                        <p>Please choose a date range.</p>
                        <div className={css({ display: 'flex', flexWrap: 'wrap' })}>
                            <div className={css({ display: 'flex' })}>
                                <StatefulCalendar
                                    monthsShown={2}
                                    range
                                    onChange={({ date }) =>
                                        onSetDates(date)
                                    }
                                />
                            </div>
                            <div className={css({ marginLeft: '15px' })}>
                                { reportDates.length >= 2 && (
                                    <div>
                                        { (fetchingReport === false && csvDownloadReady === false) && (
                                            <button
                                                className={css({
                                                    cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                                })}
                                                onClick={onFetchReport}
                                            >Fetch data for periods {moment(reportDates[0]).format('MM/DD/YYYY')} - {moment(reportDates[1]).format('MM/DD/YYYY')}
                                            </button>)}

                                        { fetchingReport === true && (
                                            <span>Fetching Report Data...</span>
                                        )}

                                        { csvDownloadReady === true && (
                                            <div>
                                                <CSVLink
                                                    className={css({
                                                        textDecoration: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                                    })}
                                                    data={assessments}
                                                    filename={`assessments${moment(reportDates[0]).format('MM/DD/YYYY')}-${moment(reportDates[1]).format('MM/DD/YYYY')}.csv`}
                                                    onClick={onDownloadReport}
                                                >CSV File Ready... Download
                                                </CSVLink>

                                                <button
                                                    className={css({
                                                        position: 'relative', top: '-2px', display: 'inline-block', border: 'none', height: '40px', marginLeft: '15px;', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                                    })}
                                                    onClick={onDownloadReport}
                                                >Clear
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <h2>Upload Student List CSV</h2>
                        <input type="file" onChange={e => onSetStudentFile(e.target.files[0])} />
                        { studentCSVFile && (
                            <button
                                className={css({
                                    position: 'relative', top: '-2px', display: 'inline-block', border: 'none', height: '40px', marginLeft: '15px;', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                })}
                                onClick={onUploadStudentCSV}
                            >Upload
                            </button>)}
                    </div>
                )}
                {(user && appMessage === false && (user.userData.role === 'ADMIN' || user.userData.role === 'REPORTER')) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        <Link
                            className={css({
                                textDecoration: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                            })}
                            to="/student-report"
                        >View Student Report
                        </Link>
                    </div>
                )}

                {(user && ((appMessage === false && user.userData.isSupervisor === true) || (appMessage === false && typeof (user.userData.proxy) !== 'undefined'))) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        <Link
                            className={css({
                                textDecoration: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                            })}
                            to="/report"
                        >View Employee Report
                        </Link>
                    </div>
                )}
            </div>

            <div className={css({ height: '75px' })} />
        </>
    );
};

export default Home;
