import React from 'react';
import { useStyletron } from 'baseui';
import { Helmet } from 'react-helmet';
import Header from '../controllers/header';
import YesNo from '../components/yessno';

const Questions = ({
    user, onLogin, assessment, setAssessmentValue, onSubmit, logError, logSaving,
}) => {
    // STYLES

    const [css] = useStyletron();

    if (!user) {
        return (
            <div className={css({ margin: '10%', textAlign: 'center' })}>
                <p>You are not currently logged in. You must log in as a valid and authorized user to proceed.</p>
                <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
            </div>
        );
    }

    let anyYes = false;
    if (assessment.q1 === 'yes' ||
        assessment.q2 === 'yes' ||
        assessment.q3 === 'yes' ||
        assessment.q4 === 'yes' ||
        assessment.q5 === 'yes' ||
        assessment.q6 === 'yes' ||
        assessment.q7 === 'yes' ||
        assessment.q8 === 'yes' ||
        assessment.q9 === 'yes') {
        anyYes = true;
    }

    // FIRST NINE HAVE BEEN ANSWERED

    const firstTen = (
        (typeof (assessment.q1) !== 'undefined') &&
        (typeof (assessment.q2) !== 'undefined') &&
        (typeof (assessment.q3) !== 'undefined') &&
        (typeof (assessment.q4) !== 'undefined') &&
        (typeof (assessment.q5) !== 'undefined') &&
        (typeof (assessment.q6) !== 'undefined') &&
        (typeof (assessment.q7) !== 'undefined') &&
        (typeof (assessment.q8) !== 'undefined') &&
        (typeof (assessment.q9) !== 'undefined'));

    const vaccineQuestionTrigger = (
        assessment.q2 === 'yes' ||
        assessment.q5 === 'yes' ||
        assessment.q6 === 'yes');

    const noToOtherQuestions = (
        assessment.q1 === 'no' &&
        assessment.q3 === 'no' &&
        assessment.q4 === 'no' &&
        assessment.q7 === 'no' &&
        assessment.q8 === 'no' &&
        assessment.q9 === 'no');

    const showVaccineQuestion = vaccineQuestionTrigger && noToOtherQuestions;

    const allAnswered = () => {
        // VACCINE QUESTION IS YES

        if (showVaccineQuestion && assessment.q13 === 'yes') {
            return true;
        }

        // IF ANY QUESTIONS ARE YES, Q15 MUST BE ANSWERED

        const q14 = (!anyYes || (anyYes && typeof (assessment.q14) !== 'undefined'));

        // IF Q15 IS YES, Q10 MUST BE ANSWERED

        const q15 = assessment.q14 !== 'yes' || (assessment.q14 === 'yes' && typeof (assessment.q15) !== 'undefined');

        return firstTen && q14 && q15;
    };

    return (
        <>
            <Helmet>
                <title>RRH Self Assessment</title>
            </Helmet>

            <Header />

            <div className={css({
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '5%',
                marginRight: '5%',
                marginTop: '0',
                '@media screen and (min-width: 564px)': {
                    marginLeft: '20%',
                    marginRight: '20%',
                },
            })}
            >
                <h2 className={css({ textTransform: 'uppercase' })}>Self Assessment</h2>
                <p>
                    <strong>Have you had any of the following symptoms since your last screening, or in the last 24 hours, that are new or unusual for you?</strong>
                </p>
                <div className={css({
                    flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '45px', minHeight: '50px',
                })}
                >
                    <div className={css({
                        flex: '2',
                        fontSize: '16px',
                        marginRight: '30px',
                        '@media screen and (min-width: 564px)': {
                            fontSize: '22px',
                        },
                    })}
                    >
                        1. Loss of sense of taste or smell?
                    </div>
                    <div className={css({ flex: '1', marginTop: '15px' })}>
                        <YesNo value={assessment.q1} onChange={value => setAssessmentValue(assessment, 'q1', value)} />
                    </div>
                </div>
                <div className={css({
                    flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '45px', minHeight: '50px',
                })}
                >
                    <div className={css({
                        flex: '2',
                        fontSize: '16px',
                        marginRight: '30px',
                        '@media screen and (min-width: 564px)': {
                            fontSize: '22px',
                        },
                    })}
                    >
                        2. Chills?
                    </div>
                    <div className={css({ flex: '1', marginTop: '15px' })}>
                        <YesNo value={assessment.q2} onChange={value => setAssessmentValue(assessment, 'q2', value)} />
                    </div>
                </div>
                <div className={css({
                    flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px',
                })}
                >
                    <div className={css({
                        flex: '2',
                        fontSize: '16px',
                        marginRight: '30px',
                        '@media screen and (min-width: 564px)': {
                            fontSize: '22px',
                        },
                    })}
                    >
                        3. Fever of 100F (37.8C) or higher?
                    </div>
                    <div className={css({ flex: '1', marginTop: '15px' })}>
                        <YesNo value={assessment.q3} onChange={value => setAssessmentValue(assessment, 'q3', value)} />
                    </div>
                </div>

                <div className={css({
                    flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px',
                })}
                >
                    <div className={css({
                        flex: '2',
                        fontSize: '16px',
                        marginRight: '30px',
                        '@media screen and (min-width: 564px)': {
                            fontSize: '22px',
                        },
                    })}
                    >
                        4. Sore throat and runny nose?
                    </div>
                    <div className={css({ flex: '1', marginTop: '15px' })}>
                        <YesNo value={assessment.q4} onChange={value => setAssessmentValue(assessment, 'q4', value)} />
                    </div>
                </div>
                <div className={css({
                    flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px',
                })}
                >
                    <div className={css({
                        flex: '2',
                        fontSize: '16px',
                        marginRight: '30px',
                        '@media screen and (min-width: 564px)': {
                            fontSize: '22px',
                        },
                    })}
                    >
                        5. Feeling like coming down with an illness (ex. Fatigue or muscle aches)?
                    </div>
                    <div className={css({ flex: '1', marginTop: '15px' })}>
                        <YesNo value={assessment.q5} onChange={value => setAssessmentValue(assessment, 'q5', value)} />
                    </div>
                </div>
                <div className={css({
                    flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px',
                })}
                >
                    <div className={css({
                        flex: '2',
                        fontSize: '16px',
                        marginRight: '30px',
                        '@media screen and (min-width: 564px)': {
                            fontSize: '22px',
                        },
                    })}
                    >
                        6. Unusual headache or eye pain?
                    </div>
                    <div className={css({ flex: '1', marginTop: '15px' })}>
                        <YesNo value={assessment.q6} onChange={value => setAssessmentValue(assessment, 'q6', value)} />
                    </div>
                </div>
                <div className={css({
                    flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px',
                })}
                >
                    <div className={css({
                        flex: '2',
                        fontSize: '16px',
                        marginRight: '30px',
                        '@media screen and (min-width: 564px)': {
                            fontSize: '22px',
                        },
                    })}
                    >
                        7. New cough or change in your cough?
                    </div>
                    <div className={css({ flex: '1', marginTop: '15px' })}>
                        <YesNo value={assessment.q7} onChange={value => setAssessmentValue(assessment, 'q7', value)} />
                    </div>
                </div>
                <div className={css({
                    flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px',
                })}
                >
                    <div className={css({
                        flex: '2',
                        fontSize: '16px',
                        marginRight: '30px',
                        '@media screen and (min-width: 564px)': {
                            fontSize: '22px',
                        },
                    })}
                    >
                        8. New or worsening shortness of breath (difficulty breathing)?
                    </div>
                    <div className={css({ flex: '1', marginTop: '15px' })}>
                        <YesNo value={assessment.q8} onChange={value => setAssessmentValue(assessment, 'q8', value)} />
                    </div>
                </div>

                <div className={css({
                    flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px',
                })}
                >
                    <div className={css({
                        flex: '2',
                        fontSize: '16px',
                        marginRight: '30px',
                        '@media screen and (min-width: 564px)': {
                            fontSize: '22px',
                        },
                    })}
                    >
                        9. Abdominal pain, nausea, vomiting, diarrhea or loss of appetite (skipping a meal)?
                    </div>
                    <div className={css({ flex: '1', marginTop: '15px' })}>
                        <YesNo value={assessment.q9} onChange={value => setAssessmentValue(assessment, 'q9', value)} />
                    </div>
                </div>

                { showVaccineQuestion && (
                    <div className={css({
                        flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '20px', minHeight: '50px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '6px',
                    })}
                    >
                        <div className={css({
                            flex: '2',
                            fontSize: '16px',
                            marginRight: '30px',
                            display: 'flex',
                            '@media screen and (min-width: 564px)': {
                                fontSize: '22px',
                            },
                        })}
                        >
                            <div><i className={`${css({
                                color: '#3e99d4', fontSize: '30px', marginLeft: '10px;', marginRight: '10px',
                            })} fas fa-question-circle`}
                            />
                            </div>
                            <span className={css({ fontWeight: 'bold' })}>Have you had a COVID-19 vaccine within 72 hours?</span>
                        </div>
                        <div className={css({ flex: '1', marginTop: '15px' })}>
                            <YesNo
                                value={assessment.q13}
                                onChange={(value) => {
                                    setAssessmentValue(assessment, 'q13', value);
                                    document.querySelector('#submitter').scrollIntoView();
                                }}
                            />
                        </div>
                    </div>
                )}

                { (firstTen && anyYes && (!showVaccineQuestion || assessment.q13 === 'no')) && (
                    <div className={css({
                        flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '20px', minHeight: '50px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '6px',
                    })}
                    >
                        <div className={css({
                            flex: '2',
                            fontSize: '16px',
                            marginRight: '30px',
                            display: 'flex',
                            '@media screen and (min-width: 564px)': {
                                fontSize: '22px',
                            },
                        })}
                        >
                            <div><i className={`${css({
                                color: '#3e99d4', fontSize: '30px', marginLeft: '10px;', marginRight: '10px',
                            })} fas fa-question-circle`}
                            />
                            </div>
                            <span className={css({ fontWeight: 'bold' })}>Have you already been evaluated by Employee Health for these symptoms?</span>
                        </div>
                        <div className={css({ flex: '1', marginTop: '15px' })}>
                            <YesNo
                                value={assessment.q14}
                                onChange={(value) => {
                                    setAssessmentValue(assessment, 'q14', value);
                                    document.querySelector('#submitter').scrollIntoView();
                                }}
                            />
                        </div>
                    </div>
                )}

                { ((anyYes && firstTen && assessment.q14 === 'yes')) && (
                    <div className={css({
                        flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '6px',
                    })}
                    >
                        <div className={css({
                            flex: '2',
                            fontSize: '16px',
                            marginRight: '30px',
                            display: 'flex',
                            '@media screen and (min-width: 564px)': {
                                fontSize: '22px',
                            },
                        })}
                        >
                            <div><i className={`${css({
                                color: '#3e99d4', fontSize: '30px', marginLeft: '10px;', marginRight: '10px',
                            })} fas fa-question-circle`}
                            />
                            </div>
                            <span className={css({ fontWeight: 'bold' })}>Have your symptoms gotten worse?</span>
                        </div>
                        <div className={css({ flex: '1', marginTop: '15px' })}>
                            <YesNo
                                value={assessment.q15}
                                onChange={(value) => {
                                    setAssessmentValue(assessment, 'q15', value);
                                    document.querySelector('#submitter').scrollIntoView();
                                }}
                            />
                        </div>
                    </div>
                )}

                <div id="submitter" className={css({ height: '80px' })}>
                    {allAnswered() && (
                        <div>
                            { logSaving !== true && (
                                <>
                                    { logError && <p className={css({ color: '#ff0000' })}>WARNING: You are either not logged in or have lost network connection.</p>}

                                    { logError && (
                                        <button
                                            className={css({
                                                marginRight: '20px', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                            })}
                                            onClick={() => onLogin()}
                                        >Login
                                        </button>)}

                                    <button
                                        className={css({
                                            cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                        })}
                                        onClick={() => onSubmit(assessment)}
                                    >{ logError ? 'Resubmit' : 'Submit' }
                                    </button>
                                </>
                            )}
                            { logSaving === true && (
                                <span>Saving Assessment...</span>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className={css({ height: '75px' })} />
        </>
    );
};

export default Questions;
