import { connect } from 'react-redux';
import Header from '../components/header';
import { showLogin } from '../state/user-actions';

const mapStateToProps = state => ({
    user: state.user ? state.user : null,
    path: state.router.location.pathname,
    appMessage: typeof (state.ui.appMessage) !== 'undefined' ? state.ui.appMessage : false,
});

const mapDispatchToProps = dispatch => ({
    onShowLogin: () => {
        dispatch(showLogin());
    },
    onLogout: () => {
        window.localStorage.setItem('RRHSAJWToken', null);
        setTimeout(() => {
            window.location.href = 'https://etauth.auth.us-east-1.amazoncognito.com/logout?response_type=code&client_id=43sodbis92907vrleg4msrpqkg&redirect_uri=https://rrhdailypass.org/login.html&scope=email+openid+profile'; // 'https://adfs.rochesterregional.org/adfs/ls/?wa=wsignout1.0';
        }, 1000);
    },
});

const HeaderController = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderController;
