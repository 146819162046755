import React from 'react';
import { StatefulCalendar } from 'baseui/datepicker';
import { CSVLink } from 'react-csv';
import { useStyletron } from 'baseui';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Header from '../controllers/header';
import spinner from '../images/spinner.gif';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const StudentReport = ({
    user, onSetDates, showDatePicker, dateRange, onShowDatePicker, onFetchReport, assessments, noCheckins, fetchingReport, assessmentsCounts,
}) => {
    // STYLES

    const [css] = useStyletron();

    if ((!user || !user.userData) || (user.userData.role !== 'ADMIN' && user.userData.role !== 'REPORTER')) {
        return (
            <div>No access</div>
        );
    }

    return (
        <>
            <Helmet>
                <title>RRH Self Assessment - Clinical Student Report</title>
            </Helmet>

            <Header />

            <div className={css({
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '5%',
                marginRight: '5%',
                marginTop: '0',
                '@media screen and (min-width: 564px)': {
                    marginLeft: '5%',
                    marginRight: '5%',
                },
            })}
            >
                <div className={css({})}>
                    <div className={css({ display: 'flex' })}>
                        <div className={css({ flex: '1' })}>
                            <h1 className={css({ fontSize: '14px' })}>Clinical Students</h1>
                            { dateRange.length === 2 && (
                                <div className={css({ fontWeight: 'bold', fontSize: '16px' })}>
                                    Report: {moment(dateRange[0]).format('MM-DD-YYYY')} to {moment(dateRange[1]).format('MM-DD-YYYY')}

                                    { (!fetchingReport && assessments.length > 0) && (
                                        <CSVLink
                                            className={css({
                                                marginLeft: '20px', textDecoration: 'none', cursor: 'pointer', backgroundColor: '#334d6e', color: '#FFFFFF', borderRadius: '16px', paddingTop: '6px', paddingBottom: '6px', paddingLeft: '12px', paddingRight: '12px', fontSize: '14px',
                                            })}
                                            data={assessments}
                                            filename={`assessments${moment(dateRange[0]).format('MM/DD/YYYY')}-${moment(dateRange[1]).format('MM/DD/YYYY')}.csv`}
                                        >Export
                                        </CSVLink>)}
                                </div>)}
                        </div>

                        <div className={css({ flex: '1', padding: '10px' })}>
                            { dateRange.length === 2 && (
                                <button
                                    className={css({
                                        marginTop: '10px;', display: 'inline-block', border: 'none', height: '40px', marginLeft: '15px;', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                    })}
                                    onClick={() => onFetchReport(true)}
                                >Fetch Report
                                </button>)}
                            { showDatePicker && (
                                <>
                                    <h2 className={css({ fontSize: '18px' })}>Choose Dates</h2>
                                    <StatefulCalendar
                                        monthsShown={2}
                                        range
                                        onChange={({ date }) =>
                                            onSetDates(date)
                                        }
                                    />
                                </>
                            )}
                            { !showDatePicker && (
                                <button
                                    className={css({
                                        display: 'inline-block', border: 'none', height: '40px', marginLeft: '15px;', cursor: 'pointer', backgroundColor: dateRange.length !== 2 ? '#acd18c' : '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                    })}
                                    onClick={() => onShowDatePicker(true)}
                                >Pick Start/End Dates
                                </button>)}
                        </div>
                    </div>

                    { (!fetchingReport && assessments.length > 0) && (
                        <div className={css({ display: 'flex', borderTop: 'solid 1px #cbcbcb' })}>
                            <div className={css({ flex: '1' })}>
                                <div className={css({ display: 'flex', marginTop: '15px;', marginBottom: '15px' })}>
                                    <span className={css({ flex: 1 })}>Approved <i className={`${css({ color: '#47a672' })} far fa-user`} />: {assessmentsCounts.approved}</span>
                                    <span className={css({ flex: 1 })}>Denied <i className={`${css({ color: '#f10b0b' })} far fa-user`} />: {assessmentsCounts.denied}</span>
                                    <span className={css({ flex: 1 })}>No Check-Ins <i className={`${css({ color: '#6ed5ec' })} far fa-user`} />: {assessmentsCounts.noCheckin}</span>
                                </div>
                                <table className={css({ width: '100%', borderCollapse: 'collapse' })}>
                                    <tbody>
                                        <tr>
                                            <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>Date</td>
                                            <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>Time</td>
                                            <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>First Name</td>
                                            <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>Last Name</td>
                                            <td className={css({ borderBottom: 'solid 1px #cbcbcb', textAlign: 'center' })}>Status</td>
                                        </tr>
                                        { assessments.map(assessment => (
                                            <tr key={assessment.id} className={css({ fontWeight: 'bold', fontSize: '16px' })}>
                                                <td>{assessment.date}</td>
                                                <td>{assessment.time}</td>
                                                <td>{assessment.firstName}</td>
                                                <td>{assessment.lastName}</td>
                                                <td className={css({ textAlign: 'center' })}><i className={`${css({ color: assessment.status === 'APPROVED' ? '#47a672' : '#f10b0b' })} far fa-user`} /></td>
                                            </tr>
                                        )) }
                                    </tbody>
                                </table>

                                <div className={css({
                                    display: 'flex', marginTop: '15px;', marginBottom: '15px',
                                })}
                                >
                                    No Check-Ins ({noCheckins.length})
                                </div>
                                <table className={css({ width: '100%', borderCollapse: 'collapse' })}>
                                    <tbody>
                                        <tr>
                                            <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>First Name</td>
                                            <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>Last Name</td>
                                        </tr>
                                        { noCheckins.map(ncUser => (
                                            <tr key={ncUser.ncUserName} className={css({ fontWeight: 'bold', fontSize: '16px' })}>
                                                <td>{ncUser.firstName}</td>
                                                <td>{ncUser.lastName}</td>
                                            </tr>
                                        )) }
                                    </tbody>
                                </table>
                            </div>

                            <div className={css({
                                flex: '1', paddingTop: '15px', paddingLeft: '45px', paddingRight: '45px',
                            })}
                            >
                                <div className={css({ fontWeight: 'bold', fontSize: '16px' })}>
                                    Based on Date Range: {moment(dateRange[0]).format('MM-DD-YYYY')} to {moment(dateRange[1]).format('MM-DD-YYYY')}
                                </div>
                                <table className={css({ width: '100%', fontWeight: 'bold' })}>
                                    <tbody>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td className={css({ textAlign: 'center' })}>Approved</td>
                                            <td className={css({ textAlign: 'center' })}>Denied</td>
                                        </tr>
                                        {assessmentsCounts.byMonth.map(m => (
                                            <tr key={m.month}>
                                                <td>{months[m.month]}</td>
                                                <td className={css({ textAlign: 'center' })}>{m.approved}</td>
                                                <td className={css({ textAlign: 'center' })}>{m.denied}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan="3">&nbsp;</td>
                                        </tr>
                                        {assessmentsCounts.byDay.map(m => (
                                            <tr key={m.date}>
                                                <td>{m.date}</td>
                                                <td className={css({ textAlign: 'center' })}>{m.approved}</td>
                                                <td className={css({ textAlign: 'center' })}>{m.denied}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>)}

                    { (!fetchingReport && assessments.length === 0 && assessmentsCounts.noCheckin > 0) && (
                        <div className={css({ display: 'flex', borderTop: 'solid 1px #cbcbcb' })}>
                            <div className={css({ flex: '1' })}>
                                <div className={css({ display: 'flex', marginTop: '15px;', marginBottom: '15px' })}>
                                    <span className={css({ flex: 1 })}>Approved <i className={`${css({ color: '#47a672' })} far fa-user`} />: {assessmentsCounts.approved}</span>
                                    <span className={css({ flex: 1 })}>Denied <i className={`${css({ color: '#f10b0b' })} far fa-user`} />: {assessmentsCounts.denied}</span>
                                    <span className={css({ flex: 1 })}>No Check-Ins <i className={`${css({ color: '#6ed5ec' })} far fa-user`} />: {assessmentsCounts.noCheckin}</span>
                                </div>

                                <div className={css({
                                    display: 'flex', marginTop: '15px;', marginBottom: '15px',
                                })}
                                >
                                    No Check-Ins ({noCheckins.length})
                                </div>
                                <table className={css({ width: '100%', borderCollapse: 'collapse' })}>
                                    <tbody>
                                        <tr>
                                            <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>First Name</td>
                                            <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>Last Name</td>
                                        </tr>
                                        { noCheckins.map(ncUser => (
                                            <tr key={ncUser.ncUserName} className={css({ fontWeight: 'bold', fontSize: '16px' })}>
                                                <td>{ncUser.firstName}</td>
                                                <td>{ncUser.lastName}</td>
                                            </tr>
                                        )) }
                                    </tbody>
                                </table>
                            </div>

                            <div className={css({
                                flex: '1', paddingTop: '15px', paddingLeft: '45px', paddingRight: '45px',
                            })}
                            >
                                    &nbsp;
                            </div>
                        </div>)}

                    { fetchingReport && (
                        <div className={css({ textAlign: 'center' })}><img alt="spinner" src={spinner} /></div>
                    )}
                </div>
            </div>
            <div className={css({ height: '75px' })} />
        </>
    );
};

export default StudentReport;
