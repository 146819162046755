import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import rootSaga from './state/sagas';
import './index.css';
import App from './controllers/app';
import createRootReducer from './state/reducers';
import * as serviceWorker from './serviceWorker';
import { initApp } from './state/user-actions';
import { setUIState } from './state/ui-actions';

// ROOT ELEMENT

const rootElement = document.getElementById('root'); // eslint-disable-line
const env = process.env.NODE_ENV;
console.log('ENV:', env);

// BROWSER HISTORY

const browserHistory = createBrowserHistory();

// MIDDLEWARE

const sagaMiddleware = createSagaMiddleware();

let store;
let composed;
if (env !== 'production') {
    const loggerMiddleware = createLogger();
    composed = compose(applyMiddleware(routerMiddleware(browserHistory), sagaMiddleware, loggerMiddleware));
    store = createStore(createRootReducer(browserHistory), composed);
} else {
    composed = compose(applyMiddleware(routerMiddleware(browserHistory), sagaMiddleware));
    store = createStore(createRootReducer(browserHistory), composed);
}

sagaMiddleware.run(rootSaga);

// RENDER APP PROVIDER

const engine = new Styletron();
const provider = (
    <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
            <Provider store={store}>
                <ConnectedRouter history={browserHistory}>
                    <App />
                </ConnectedRouter>
            </Provider>
        </BaseProvider>
    </StyletronProvider>);

ReactDOM.render(provider, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// APP INIT

store.dispatch(initApp());

// Listen for app needs updating message

window.addEventListener('message', (event) => {
    if (event.data === 'App requires update') {
        console.log('Receiving update message:', event.data);
        store.dispatch(setUIState('showUpdateApp', true));
    }
}, false);
