export const SET_UI_VALUE = 'SET_UI_VALUE';

// Store user info into local state.

export function setUIState(field, value) {
    return {
        type: SET_UI_VALUE,
        field,
        value,
    };
}
